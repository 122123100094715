import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import './FurnitureListing.scss';
import { Row, Col, Button } from 'antd';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import ModalBox from '../../Components/ModalBox/ModalBox';
import SelectBox from '../../Components/SelectBox/SelectBox';
import FurnitureFilter from './FurnitureFilter/FurnitureFilter';
import QuickView from './QuickView/QuickView';

class FurnitureListing extends React.Component {
    constructor() {
        super()
        this.state = {
            quickView: false,
            sortBy: [],
            defaultActiveKey: []
        }
    }
    onCancel = () => {
        this.setState({ quickView: false });
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners furniture">
                    <div className="container mx-auto">
                        <h1>Furniture</h1>
                        <p>Our Furniture Includes Luxurious Chairs, Sturdy Desks, Chic Wardrobes, And Comfortable Beds.</p>
                        <p>You Will Find Pre-Selected, Tasteful Furniture For Your Kitchen, Living Room, Dining Room, And Bedroom. </p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">
                        <BreadCrumbs {...this.props} navigations={[{ name: 'Home', link: '/' }, { name: 'Furniture', link: '/furniture' }, { name: 'Living room', link: '/furniture-listing' }]} />
                         {/* BreadCrumbs and filter ends */}
                         
                         <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <FurnitureFilter />
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 17 }} lg={{ span: 17 }}>

                                <div className="no-product-available">
                                    <Row gutter={[8, 8]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h3>No Products Available !</h3>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <img src={require('../../assets/images/icons/box.svg')} alt="No Products Available" />
                                        </Col>
                                    </Row>
                                </div>

                                {/* title and filters */}
                                <Row gutter={[8, 8]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                        <h2>Products you might like</h2>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="sort-by">
                                            <SelectBox
                                                label="Sort By"
                                                id="durations"
                                                value={this.state.softBy}
                                                options={this.state.softBy}
                                                onChangeText={this.onChangeText}
                                                optional="true"
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {/* furniture listings */}
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="furniture-grid">
                                            <div className="details">
                                                <div className="image">
                                                    <img src={require("../../assets/images/furniture/listings/furniture-1.webp")} alt="" />
                                                    <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                </div>
                                                <h4>Colvill Solid Wood Arm Chair</h4>
                                                <div className="other-details">
                                                    <div className="rent">
                                                        <span className="title">Rent</span>
                                                        <span className="price">$ 12/mon</span>
                                                    </div>
                                                    <div className="shipping">
                                                        <span>ships in 4 days</span>
                                                    </div>
                                                    <div className="quick-view">
                                                        <Button type="secondary" onClick={() => this.setState({ quickView: true })} >Quick View</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="furniture-grid">
                                            <div className="details">
                                                <div className="image">
                                                    <img src={require("../../assets/images/furniture/listings/furniture-2.webp")} alt="" />
                                                    <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                </div>
                                                <h4>Hugo 2 Seater Sofa - White</h4>
                                                <div className="other-details">
                                                    <div className="rent">
                                                        <span className="title">Rent</span>
                                                        <span className="price">$ 12/mon</span>
                                                    </div>
                                                    <div className="shipping">
                                                        <span>ships in 4 days</span>
                                                    </div>
                                                    <div className="quick-view">
                                                        <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="furniture-grid">
                                            <div className="details">
                                                <div className="image">
                                                    <img src={require("../../assets/images/furniture/listings/furniture-3.webp")} alt="" />
                                                    <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                </div>
                                                <h4>Hugo 4 Seater L shape Sofa</h4>
                                                <div className="other-details">
                                                    <div className="rent">
                                                        <span className="title">Rent</span>
                                                        <span className="price">$ 12/mon</span>
                                                    </div>
                                                    <div className="shipping">
                                                        <span>ships in 4 days</span>
                                                    </div>
                                                    <div className="quick-view">
                                                        <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>

                {/* Quick view modal box */}
                <ModalBox
                    visible={!!this.state.quickView}
                    width={854}
                    wrapClassName="main-search-area"
                    onCancel={this.onCancel}
                    footer={null}
                    destroyOnClose>
                    <QuickView />
                </ModalBox>
                {/* Quick view modal box ends */}


            </React.Fragment >
        )
    }
}
export default withRouter(FurnitureListing)