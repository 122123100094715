import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import '../MyDashboard.scss';
import SiderMenu from '../SiderMenu';
import InputBox from '../../../Components/InputBox/InputBox';

class MyInventory extends React.Component {
    constructor() {
        super()
        this.state = {
            searcbyOrderno: '',
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="my-dashboard margin-bottom20">
                            <h1>My Dashboard</h1>
                        </div>
                        <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <SiderMenu />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                <div className="card my-orders light-color">
                                    <Row>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h2>My Inventory</h2>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="form-fields">
                                                <InputBox
                                                    placeholder="Search by Product name or order no."
                                                    id="searcbyOrderno"
                                                    value={this.state.searcbyOrderno}
                                                    onChangeText={this.onChangeText}
                                                    type="text"
                                                    optional="true"
                                                    suffix={"fa fa-search"}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    {/* list starts here */}

                                    <div className="orders-containers">
                                        <div className="card">
                                            <div className="ordered-items">
                                                <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                        <img src={require('../../../assets/images/cart/living-room.webp')} alt="Valentino Armchair in Teak Finish" />
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                                        <h4>Valentino Armchair in Teak Finish</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                        <div className="specfications">
                                                            <div className="added-on">
                                                                <h5>Added On</h5>
                                                                <span>12/May/2020</span>
                                                            </div>
                                                            <div className="quantity">
                                                                <h5>Quantity</h5>
                                                                <span>5</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                        <div className="action-buttons">
                                                            <Button type="secondary">Add to COOP</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* list ends here */}
                                        </div>
                                    </div>
                                    {/* list ends here */}

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(MyInventory)