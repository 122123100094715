import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Popover, Checkbox } from 'antd'
import SelectBox from '../../../Components/SelectBox/SelectBox';
import InputBox from '../../../Components/InputBox/InputBox';

class OrderDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            couponCode: '',
            selectMonth: [],
            durations: [],
            defaultActiveKey: []
        }
    }
    // popover
    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="order-pricing-details">
                    <div className="card-view">
                        <div className="title">
                            <h3>Renting Details</h3>
                        </div>
                        <div className="form-fields">
                            <SelectBox
                                label="Durations"
                                id="durations"
                                value={this.state.durations}
                                options={this.state.durations}
                                onChangeText={this.onChangeText}
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <SelectBox
                                label="Select Month"
                                placeholder="12/08/20"
                                id="selectMonth"
                                value={this.state.selectMonth}
                                options={this.state.selectMonth}
                                onChangeText={this.onChangeText}
                                optional="true"
                            />
                        </div>
                    </div>
                    {/* Renting Details ends */}

                    <div className="coupon-code">
                        <div className="title">
                            <h3>Have a coupon code?</h3>
                            <div className="form-fields">
                                <InputBox
                                    placeholder="Enter coupon code"
                                    id="couponCode"
                                    value={this.state.couponCode}
                                    onChangeText={this.onChangeText}
                                    type="text"
                                    optional="true"
                                    prefix={"fa fa-gift"}
                                />
                            </div>
                            <Button type="primary">Apply</Button>
                        </div>
                    </div>
                    {/* coupon code ends here */}

                    <div className="card-view">
                        <div className="title">
                            <h3>Renting Details</h3>
                        </div>
                        <div className="rental-details">
                            <div className="monthly-rental">Monthly rental <span>$ 18</span></div>
                            <div className="monthly-rental">Monthly rental <span>$ 24</span></div>
                            <div className="refund-deposite">Refundable Security Deposit
                                <Popover
                                    content="Lorem Ipsum is simply dummy text of the printing."
                                    trigger="click"
                                    visible={this.state.visible}
                                    onVisibleChange={this.handleVisibleChange}
                                >
                                    <i className="fa fa-info-circle" />
                                </Popover>
                                <span>$ 42</span>
                            </div>
                        </div>
                        <div className="sub-total">
                            <div className="price">Subtotal <span>$ 84</span></div>
                            <p>Delivery Fee & Taxes will be calculated at checkout (One time payment will be charged for Delivery)</p>
                        </div>
                        <div className="action-button">
                            <Button type="primary">Place Order</Button>
                        </div>
                    </div>
                    {/* renting details ends */}
                    <div className="card-view">
                        <div className="title">
                            <p>Would you like to save this order as an open order?</p>
                        </div>
                        <div className="margin-bottom20">
                            <Checkbox>Save in existing open order</Checkbox>
                            <Checkbox>Save as new open order</Checkbox>
                        </div>
                        <div className="action-button">
                            <Button type="primary">Save As Open Order</Button>
                        </div>
                    </div>
                    {/* save order ends*/}

                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(OrderDetails)