import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import './PersonalDetails.scss';
import InputBox from '../../Components/InputBox/InputBox';
import PersonalDetailsNavigation from './PersonalDetailsNavigation';

class EditPersonalDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            name: '',
            moileNumber: '',
            emailAddress: ''
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="edit-personal-details">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <PersonalDetailsNavigation />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom10">Personal Details</h2>
                                    <div className="card margin-bottom20">
                                        <div className="form-fields">
                                            <InputBox
                                                label="Name"
                                                id="name"
                                                value={this.state.name}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional="true"
                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                label="Mobile Number"
                                                id="moileNumber"
                                                value={this.state.moileNumber}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional="true"
                                            />
                                            <div className="action-buttons">
                                                <Button type="link">Verify Number</Button>
                                            </div>
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                label="Email Address"
                                                id="emailAddress"
                                                value={this.state.emailAddress}
                                                onChangeText={this.onChangeText}
                                                type="email"
                                                optional="true"
                                            />
                                            <div className="action-buttons">
                                                <Button type="link">Verify Email Address</Button>
                                            </div>
                                        </div>

                                        <div className="action-buttons padding-top20">
                                            <Button type="secondary">Cancel</Button>
                                            <Button type="primary">Save Details</Button>
                                        </div>
                                    </div>
                                    {/* personal details ends */}

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(EditPersonalDetails)