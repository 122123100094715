import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import './QuickView.scss';

class QuickView extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="quick-view-furniture">
                    <div className="title">
                        <h2>Hugo 2 Seater Sofa</h2>
                        <p>Woodsworth is our premium homegrown label thats part classic, part contemporary. A delectable amalgamation of the timeless and trendy, a precarious balance of function and flair. Woodsworth is a harmonious fit for all tastes, styles and spaces.</p>
                    </div>
                    <Row gutter={[20, 10]}>
                        <Col xs={{ span: 24 }} sm={{ span: 15 }} md={{ span: 15 }} lg={{ span: 15 }}>
                            <img src={require('../../../assets/images/furniture/overview.webp')} alt="Furnture Overview" />
                            <div className="other-details">
                                <div className="rent">
                                    <span className="title-price">Rent: $ 12/mon</span>
                                </div>
                                <div className="shipping">
                                    <span>ships in 4 days</span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }}>
                            <div className="prouct-details">
                                <div className="list">
                                    <span className="title">Product</span>
                                    <span className="option">Available</span>
                                </div>
                                <div className="list">
                                    <span className="title">Group</span>
                                    <span className="option">Living room</span>
                                </div>
                                <div className="list">
                                    <span className="title">Sub-group</span>
                                    <span className="option">Bedroom Dresser</span>
                                </div>
                                <div className="list">
                                    <span className="title">Dimensions</span>
                                    <span className="option">66”W x 19”D x 33.5”H</span>
                                </div>
                                <div className="list">
                                    <span className="title">Characteristics</span>
                                    <span className="option">--</span>
                                </div>
                                <div className="list">
                                    <span className="title">Color</span>
                                    <span className="option">Grey, wood</span>
                                </div>
                                <div className="list">
                                    <span className="title">Style</span>
                                    <span className="option">Modern, transitional, urban, glam</span>
                                </div>
                                <div className="list">
                                    <span className="title">AIN</span>
                                    <span className="option">BDD10006</span>
                                </div>
                            </div>
                            <div className="action-button">
                                <Button type="secondary">View Details</Button>
                                <Button type="primary">Add To Cart</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(QuickView)