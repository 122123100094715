import React from "react";
import { Link } from "react-router-dom";
import BundlesHome from "./BundlesHome/BundlesHome";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import Testimonials from "./Testimonials/Testimonials";
import StagingDepotCommitment from "./StagingDepotCommitment/StagingDepotCommitment";

import { Row, Col} from "antd";

{/*import image from "../../assets/images/main-slider/slider6.jpg";*/}
{/*import image from "../assets/images/main-slider/b5.jpg";*/}



export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        
        <HomeSlider />
        {/* main slider ends */}

        {/* home links section starts */}
        <section className="empty-legs-flight-cost">
          <div className="container mx-auto">
            <div className="valetaboutus">
            <h2>ABOUT US </h2>
            <p>
            Valet Parking is the World's first Digital Platform for all Valet Parking needs Worldwide. <br></br>
            Valet Parking offers Automated Valet Parking Service right from choosing the Valet, <br></br>
             Track your precious car and also ensures that it is safely parked in a certified parking<br></br> lot.
             Valet Parking has partnered with the Best Technology brand to offer the safety of<br></br> your car and 
             also to ensure that your car keys are handed over to a certified valet.<br></br>
             Valet Parking can be used largely by Sectors in Hospitality, Retail,
             Luxury Retail, Malls,<br></br> Cineplexes, Serviced Residences, Clubs, Events, Weddings and much more.
            </p>
            </div>
      </div>

      <div className="build">
        <div className="bui">
               <img src={require('../../assets/images/valerparking2.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="carbrands">
          <p1>Our Brands</p1>
        </div>
     <div className="allcarbrands">
        <div className="carbrand">
        <div className="carbr">
               <img src={require('../../assets/images/audilogo.jpg')} alt="google signin" />
        </div> 
        </div>
     
        <div className="bmw">
        <div className="carbr">
               <img src={require('../../assets/images/volvologo1.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="bmw1">
        <div className="carbr">
               <img src={require('../../assets/images/bmwlogo1.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="jaguar">
        <div className="carbr">
               <img src={require('../../assets/images/jaguarlogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="porsche">
        <div className="carbr">
               <img src={require('../../assets/images/porschelogo.jpg')} alt="google signin" />
        </div> 
        </div>
        </div>
          
        <div className="lexus">
        <div className="carbr">
               <img src={require('../../assets/images/lexuslogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="Rangerover">
        <div className="carbr">
               <img src={require('../../assets/images/Rangeroverlogo.jpg')} alt="google signin" />
        </div> 
        </div>
      
        <div className="maserati">
        <div className="carbr">
               <img src={require('../../assets/images/maseratilogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="lamborghini">
        <div className="carbr">
               <img src={require('../../assets/images/lamborghinilogo.jpg')} alt="google signin" />
        </div> 
        </div>

        
        <div className="Ferrari">
        <div className="carbr">
               <img src={require('../../assets/images/ferrarilogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="Martin">
        <div className="carbr">
               <img src={require('../../assets/images/martinlogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="Cardillac">
        <div className="carbr">
               <img src={require('../../assets/images/cadillaclogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="Maclaren">
        <div className="carbr">
               <img src={require('../../assets/images/mclarenlogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="Rollsroyace">
        <div className="carbr">
               <img src={require('../../assets/images/rollslogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="Bugatti">
        <div className="carbr">
               <img src={require('../../assets/images/bugattilogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="carbrands">
          <p1>Our Partners</p1>
        </div>


        <div className="partners">
        <div className="getmy">
        <div className="carbr">
               <img src={require('../../assets/images/getmylogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="wohr">
        <div className="carbr">
               <img src={require('../../assets/images/wohrlogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="flowbird">
        <div className="carbr">
               <img src={require('../../assets/images/flowbirdlogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="mobotix">
        <div className="carbr">
               <img src={require('../../assets/images/mobotixlogo.jpg')} alt="google signin" />
        </div> 
        </div>

        <div className="skidata">
        <div className="carbr">
               <img src={require('../../assets/images/skidatalogo.jpg')} alt="google signin" />
        </div> 
        </div>
        </div>
      
        </section>


        {/* Bundles starts */}
         {/* <BundlesHome />*/}
        {/* Bundles starts */}

        {/* Testimonials starts */}
        {/* <Testimonials /> */}
        {/* Testimonials ends */}

        {/* StagingDepotCommitment starts */}
       {/*<StagingDepotCommitment />*/}
        {/* StagingDepotCommitment ends here */}
      </React.Fragment>
    );
  }
}
