import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import Home from '../Screens/LandingPage/Home';
import HowWorks from '../Screens/HowWorks/HowWorks';
import DesignerServices from '../Screens/DesignerServices/DesignerServices';
import Inspirations from '../Screens/Inspirations/Inspirations';
import Bundles from  '../Screens/Bundles/Bundles';
import BundlesListing from '../Screens/BundlesListing/BundlesListing';
import BundlesDetailedPage from '../Screens/BundlesDetailedPage/BundlesDetailedPage';
import Furniture from '../Screens/Furniture/Furniture';
import FurnitureListing from '../Screens/FurnitureListing/FurnitureListing';
import Offers from '../Screens/Offers/Offers';
import AboutUs from '../Screens/AboutUs/AboutUs';
import Culture from '../Screens/Culture/Culture';
import MyCart from '../Screens/MyCart/MyCart';
import MyWishlist from '../Screens/MyWishlist/MyWishlist';
import OpenOrders from '../Screens/OpenOrders/OpenOrders';
import RecentlyViewed from '../Screens/RecentlyViewed/RecentlyViewed';
import PersonalDetails from '../Screens/PersonalDetails/PersonalDetails';
import EditPersonalDetails from '../Screens/PersonalDetails/EditPersonalDetails';
import LiveOrders from '../Screens/MyDashboard/LiveOrders/LiveOrders';
import OrderHistory from '../Screens/MyDashboard/OrderHistory/OrderHistory';
import MyInventory from '../Screens/MyDashboard/MyInventory/MyInventory';
import UndeliveredOrders from '../Screens/MyDashboard/UndeliveredOrders/UndeliveredOrders';
import TermsAndCondition from '../Screens/TermsAndCondition';
import PrivacyPolicy from '../Screens/PrivacyPolicy';
import Layout from '../Layout/Layout';
import Dropdwn from '../Screens/Dropdwn';
import ContactUs from '../Screens/ContactUs';

class InnerRoutes extends React.Component {
    render() {
        const {
            match: { url },
        } = this.props;
        return (
            <Layout {...this.props}>
                <Switch>
                    <Route exact path={``} component={Home} />
                    <Route exact path={`/how-it-works`} component={HowWorks} />
                    <Route exact path={`/designer-services`} component={DesignerServices} />
                    <Route exact path={`/inspirations`} component={Inspirations} />
                    <Route exact path={`/bundles`} component={Bundles} />
                    <Route exact path={`/bundles-listing`} component={BundlesListing} />       
                    <Route exact path={`/bundles-detailed-page`} component={BundlesDetailedPage} />     
                    <Route exact path={`/furniture`} component={Furniture} />
                    <Route exact path={`/furniture-listing`} component={FurnitureListing} />
                    <Route exact path={`/offers`} component={Offers} />
                    <Route exact path={`/about-us`} component={AboutUs} />
                    <Route exact path={`/culture`} component={Culture} />
                    <Route exact path={`/my-cart`} component={MyCart} />
                    <Route exact path={`/my-wishlist`} component={MyWishlist} />
                    <Route exact path={`/open-orders`} component={OpenOrders} />
                    <Route exact path={`/recently-viewed`} component={RecentlyViewed} />
                    <Route exact path={`/personal-details`} component={PersonalDetails} />
                    <Route exact path={`/edit-personal-details`} component={EditPersonalDetails} />
                    <Route exact path={`/live-orders`} component={LiveOrders} />
                    <Route exact path={`/order-history`} component={OrderHistory} />
                    <Route exact path={`/my-inventory`} component={MyInventory} />
                    <Route exact path={`/TermsAndCondition`} component={TermsAndCondition} />
                    <Route exact path={`/PrivacyPolicy`} component={PrivacyPolicy} />
                    <Route exact path={`/Dropdwn`} component={Dropdwn} />
                    <Route exact path={`/ContactUs`} component={ContactUs} />
                 


                </Switch>
            </Layout>
        );
    }
}

export default InnerRoutes;
