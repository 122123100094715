import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import './BundlesHome.scss';
import { Row, Col, Button } from 'antd';
import Slider from "react-slick";

const Bundlesslidersettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
};

class BundlesHome extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="bundles-products">
                    <div className="section-title">
                        <h1>Bundles</h1>
                    </div>

                    <div className="bundles-products-container">
                        <Row align="center">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                <div className="bundles-slider">
                                    {/* <!--Slider Item starts--> */}
                                    <Slider {...Bundlesslidersettings}>
                                        <div className="item">
                                            <div className="details">
                                                <div className="image">
                                                    <img src={require("../../../assets/images/bundles/bundles-1.webp")} alt="" />
                                                </div>
                                                <div className="content">
                                                    <div className="title">
                                                        <h3>Bundles</h3>
                                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquya…</p>
                                                    </div>
                                                    <div className="item-includes">
                                                        <h5>Items Included</h5>
                                                    </div>
                                                    <div className="bottom-section">
                                                        <div className="rent-details">
                                                            <span className="rent">Rent</span>
                                                            <span className="rent">$ 45/mon</span>
                                                        </div>
                                                        <div className="view-bundles">
                                                            <Button className="btn btn-secondary">View Details</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* item ends */}

                                        <div className="item">
                                            <div className="details">
                                                <div className="image">
                                                    <img src={require("../../../assets/images/bundles/bundles-1.webp")} alt="" />
                                                </div>
                                                <div className="content">
                                                    <div className="title">
                                                        <h3>Bundles</h3>
                                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquya…</p>
                                                    </div>
                                                    <div className="item-includes">
                                                        <h5>Items Included</h5>
                                                    </div>
                                                    <div className="bottom-section">
                                                        <div className="rent-details">
                                                            <span className="rent">Rent</span>
                                                            <span className="rent">$ 45/mon</span>
                                                        </div>
                                                        <div className="view-bundles">
                                                            <Button className="btn btn-secondary">View Details</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* item ends */}

                                    </Slider>
                                    {/* <!--Slider Item ends--> */}
                                </div>

                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <div className="view-all">
                                    <Link>View All</Link>
                                </div>
                                <div className="caption">
                                    <p>Our furniture packages include luxurious chairs, sturdy desks, chic wardrobes, and comfortable beds. You will find pre-selected, tasteful furniture for your kitchen, living room, dining room, and Bedroom. </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withRouter(BundlesHome)