import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Button, Col } from 'antd';

class OrderSummary extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                        <h2>Living Room</h2>
                    </Col>
                    <Col className="text-right" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <Link>Edit Cart</Link>
                    </Col>
                </Row>
                <div className="orders-containers">
                    <div className="ordered-items">
                        <Row gutter={[20, 20]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <img src={require('../../../assets/images/cart/living-room.webp')} alt="Valentino Armchair in Teak Finish" />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 14 }} lg={{ span: 14 }}>
                                <h4>Valentino Armchair in Teak Finish</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className="specfications">
                                    <div className="rent-price">
                                        <h5>Rent</h5>
                                        <span>$18/mon</span>
                                    </div>
                                    <div className="color">
                                        <h5>Color</h5>
                                        <span>Orange</span>
                                    </div>
                                    <div className="quantity">
                                        <h5>Quantity</h5>
                                        <span>1</span>
                                    </div>
                                    <div className="room">
                                        <h5>Room</h5>
                                        <span>Living Room 1</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                <div className="edit-delete-action">
                                    <Button type="link"><i className="flaticon-edit" /></Button>
                                    <Button type="link"><i className="flaticon-delete-1" /></Button>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    {/* list ends here */}
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(OrderSummary)