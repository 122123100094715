import React from 'react';
import { withRouter } from 'react-router';
import {  Button } from 'antd';
import './PersonalDetails.scss';

class Cards extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="saved-address">
                    <div className="address-fields">
                        <div className="list">
                            <div className="label">Card Number :</div>
                            <div className="content">4201 #### #### ##25</div>
                        </div>
                        <div className="list">
                            <div className="label">Card Holder’s Name :</div>
                            <div className="content">Robert Jhonson</div>
                        </div>
                        <div className="list">
                            <div className="label">Valid Through :</div>
                            <div className="content">11/28</div>
                        </div>
                    </div>
                    <div className="edit-delete-action">
                        <Button type="link"><i className="flaticon-delete-1" /></Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Cards)