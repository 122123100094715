import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Button, Col, Input, Tooltip } from "antd";
import mainlogo from "../assets/images/valetlogo.jpeg";
import ModalBox from "../Components/ModalBox/ModalBox";
import SigninSignup from "../Screens/SigninSignup/SigninSignup";
import { SearchOutlined } from "@ant-design/icons";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const menu = (
  <Menu> 
    <Menu.Item key="0">
      <a target="#" rel="#" href="#">
        Africa
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="#">
        America
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="#">
        India
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="#">
        Australia
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="#">
        Russia
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="#">
        Dubai
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="#">
        Mexico
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="#">
        UK
      </a>
    </Menu.Item>
  </Menu>
);

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      signinsignup: false,
      headerView: false,
      name: "",
    };
  }


  onCancel = () => {
    this.setState({ open: false });
    this.setState({ signinsignup: false });
  };
  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        <div className="cta-header">
          <div className="container mx-auto">
            <Row align="center">
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                align="left"
              >
                <div className="book-jets">
                
                  <a href="mailto:info@rentpartners.com">
                    <i className="flaticon-email-1" /> info@valetparking.com
                  </a>
                </div>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                align="right"
              >
                <div className="social-media">
                  <ul>
                    <li>
                      <Link>
                        <i className="flaticon-facebook-3" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="flaticon-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="flaticon-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="flaticon-youtube-1" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="main-hearder">
          <div className="container mx-auto">
            <div className="top-header">
              <Row>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                >
                  <div className="logo1">
                  <div className="left-section">
                    <div className="logo">
                      <Link to="/">
                        <img src={mainlogo} alt="Staging Depot"  width="100px" />{" "}
                      </Link>
                    </div>
                  </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 17, order: 2 }}
                  lg={{ span: 17, order: 2 }}
                >
                  <div className="bottom-header">
                    <ul>
                      <li>
                        <Link>Home </Link>
                      </li>
                      
                      <li>
                    
                        <Link to="/AboutUss">About Us</Link>
                      </li>
                      
                      <li>
                        <Link to="/ContactUs">Contact Us</Link>
                      </li>

                      <li className="dropdown">
                        <Dropdown overlay={menu}>
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            Countries <DownOutlined />
                          </a>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 3 }}
                  md={{ span: 3, order: 3 }}
                  lg={{ span: 3, order: 3 }}
                  align="right"
                >
                  <Tooltip title="search">
                    <Button
                      className="search-button"
                      shape="circle"
                      icon={<SearchOutlined />}
                      size="large"
                      color="#0f2765;"
                    />
                  </Tooltip>
                  {/* <div className="signin-signup">
                    <Button
                      onClick={() => this.setState({ signinsignup: true })}
                      type="primary"
                    >
                      Sign in / Sign up
                    </Button>
                  </div> */}
                </Col>
              </Row>
            </div>

            {/* bottom header starts */}

            {/* bottom header ends */}
          </div>
        </div>

        <ModalBox
          content="test"
          visible={!!this.state.signinsignup}
          width={900}
          onCancel={this.onCancel}
          footer={null}
          destroyOnClose
        >
          <SigninSignup />
        </ModalBox>
      </React.Fragment>
    );
  }
}
