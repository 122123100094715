import React from 'react';
import { withRouter } from 'react-router';
import {  Button } from 'antd';
import './PersonalDetails.scss';

class Address extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="saved-address">
                    <div className="address-fields">
                        <div className="list">
                            <div className="label">Address Line 1 :</div>
                            <div className="content">14 Darklake View</div>
                        </div>
                        <div className="list">
                            <div className="label">District :</div>
                            <div className="content">Estover</div>
                        </div>
                        <div className="list">
                            <div className="label">State :</div>
                            <div className="content">New York</div>
                        </div>
                        <div className="list">
                            <div className="label">Pincode :</div>
                            <div className="content">12401</div>
                        </div>
                    </div>
                    <div className="edit-delete-action">
                        <Button type="link"><i className="flaticon-edit" /></Button>
                        <Button type="link"><i className="flaticon-delete-1" /></Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Address)