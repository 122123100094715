import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Divider, Checkbox, Slider, Button, Collapse, Switch } from 'antd';
import DateBox from '../../../Components/DateBox/DateBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import { PlusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const marks = {
    10: '$ 10',
    500: '$ 500'
};
class FurnitureFilter extends React.Component {

    constructor() {
        super()
        this.state = {
            fromDate: '',
            durations: [],
            defaultActiveKey: []
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    render() {
        return (
            <React.Fragment>
                <div className="Filter">
                    <Row className="filter-heading">
                        <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <h1><i className="fa fa-filter" /> Filters</h1>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <Button type="secondary">Reset</Button>
                        </Col>
                    </Row>
                    {/* filter heading ends */}

                    <Divider />

                    <div className="filter-sections">
                        <h4>View By Availability</h4>
                        <p>Look For Items Available During This Period (Your Rental Duration)</p>
                        <div className="form-fields">
                            <DateBox
                                label="From"
                                placeholder="12/08/20"
                                id="fromDate"
                                value={this.state.fromDate}
                                onChangeText={this.onChangeText}
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <SelectBox
                                label="Durations"
                                id="durations"
                                value={this.state.durations}
                                options={this.state.durations}
                                onChangeText={this.onChangeText}
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <Checkbox>Show Only Available Products</Checkbox>
                        </div>
                    </div>

                    <Divider dashed />

                    <div className="filter-sections">
                        <h4>Price Range</h4>
                        <div className="price-range">
                            <Slider
                                marks={marks}
                                min={0}
                                max={500}
                                defaultValue={10}
                            />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className="filter-sections margin-bottom20">
                        <div className="options-type">
                            <div className="list-item">
                                <label>My Inventory <Switch defaultChecked size="small" /></label>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                            </div>
                            <div className="list-item">
                                <label>Depot Inventory <Switch defaultChecked size="small" /></label>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                            </div>
                            <div className="list-item">
                                <label>COOP Inventory <Switch  size="small" /></label>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                            </div>
                        </div>
                    </div>

                    {/* accordian view starts */}
                    <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition='right'
                        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                        ghost
                    >
                        <Panel header="Living room" key="1">
                            <div className="filter-sections">
                                <div className="options-type">
                                    <div className="options-list">
                                        <Checkbox>Bar</Checkbox>
                                        <Checkbox>Bench</Checkbox>
                                        <Checkbox>Chair</Checkbox>
                                        <Checkbox>Coffee end table</Checkbox>
                                        <Checkbox>Console</Checkbox>
                                        <Checkbox>Loveseat</Checkbox>
                                        <Checkbox>Sofa</Checkbox>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Bed Room" key="2">
                            <div className="filter-sections">
                                <div className="options-type">
                                    <div className="options-list">
                                        <Checkbox>Bar</Checkbox>
                                        <Checkbox>Bench</Checkbox>
                                        <Checkbox>Chair</Checkbox>
                                        <Checkbox>Coffee end table</Checkbox>
                                        <Checkbox>Console</Checkbox>
                                        <Checkbox>Loveseat</Checkbox>
                                        <Checkbox>Sofa</Checkbox>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Dining room" key="3">
                            <div className="filter-sections">
                                <div className="options-type">
                                    <div className="options-list">
                                        <Checkbox>Bar</Checkbox>
                                        <Checkbox>Bench</Checkbox>
                                        <Checkbox>Chair</Checkbox>
                                        <Checkbox>Coffee end table</Checkbox>
                                        <Checkbox>Console</Checkbox>
                                        <Checkbox>Loveseat</Checkbox>
                                        <Checkbox>Sofa</Checkbox>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Office" key="4">
                            <div className="filter-sections">
                                <div className="options-type">
                                    <div className="options-list">
                                        <Checkbox>Bar</Checkbox>
                                        <Checkbox>Bench</Checkbox>
                                        <Checkbox>Chair</Checkbox>
                                        <Checkbox>Coffee end table</Checkbox>
                                        <Checkbox>Console</Checkbox>
                                        <Checkbox>Loveseat</Checkbox>
                                        <Checkbox>Sofa</Checkbox>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="outdoor" key="5">
                            <div className="filter-sections">
                                <div className="options-type">
                                    <div className="options-list">
                                        <Checkbox>Bar</Checkbox>
                                        <Checkbox>Bench</Checkbox>
                                        <Checkbox>Chair</Checkbox>
                                        <Checkbox>Coffee end table</Checkbox>
                                        <Checkbox>Console</Checkbox>
                                        <Checkbox>Loveseat</Checkbox>
                                        <Checkbox>Sofa</Checkbox>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Accessories" key="6">
                            <div className="filter-sections">
                                <div className="options-type">
                                    <div className="options-list">
                                        <Checkbox>Bar</Checkbox>
                                        <Checkbox>Bench</Checkbox>
                                        <Checkbox>Chair</Checkbox>
                                        <Checkbox>Coffee end table</Checkbox>
                                        <Checkbox>Console</Checkbox>
                                        <Checkbox>Loveseat</Checkbox>
                                        <Checkbox>Sofa</Checkbox>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>

                    {/* accordian view ends */}

                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(FurnitureFilter)