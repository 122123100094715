import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Popover, Checkbox } from 'antd';
import './OpenOrders.scss';


class OrderDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            visible: false,
        }
    }
    // popover
    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };
    render() {
        return (
            <React.Fragment>
                <div className="address-details">
                    <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                            <p>Address Details :</p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                            <div className="address">14 Darklake View, Estover New York 12401</div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 14 }} lg={{ span: 14 }} className="text-right">
                            <h2>Would you like to modify this order?</h2>
                            <Button type="primary">Modify Order</Button>
                        </Col>
                    </Row>
                </div>

                <div className="orders-containers">
                    <div className="ordered-items">
                        <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                <Checkbox />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                <img src={require('../../assets/images/cart/living-room.webp')} alt="Valentino Armchair in Teak Finish" />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 13 }} lg={{ span: 13 }}>
                                <h4>Valentino Armchair in Teak Finish</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className="specfications">
                                    <div className="rent-price">
                                        <h5>Rent</h5>
                                        <span>$18/mon</span>
                                    </div>
                                    <div className="color">
                                        <h5>Color</h5>
                                        <span>Orange</span>
                                    </div>
                                    <div className="quantity">
                                        <h5>Quantity</h5>
                                        <span>1</span>
                                    </div>
                                    <div className="room">
                                        <h5>Room</h5>
                                        <span>Living Room 1</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="edit-delete-action text-right">
                                    <Button type="link"><i className="flaticon-edit" /></Button>
                                    <Button type="link"><i className="flaticon-delete-1" /></Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* list ends here */}
                </div>

                <div className="payable-amount">
                    <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <h3>Payable Amount</h3>
                            <div className="monthly-rental">
                                Monthly rental
                                <span>$ 18</span>
                            </div>
                            <div className="security-deposit">
                                Refundable Security Deposit
                                <Popover
                                    content="Lorem Ipsum is simply dummy text of the printing."
                                    trigger="click"
                                    visible={this.state.visible}
                                    onVisibleChange={this.handleVisibleChange}
                                >
                                    <i className="fa fa-info-circle" />
                                </Popover>
                                <span>$ 42</span>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
                            <div className="sub-total">
                                <h3>Subtotal</h3>
                                <span>$ 84</span>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} className="text-center">
                            <p>Delivery Fee & Taxes will be calculated at checkout (One time payment will be charged for Delivery)</p>
                            <Button type="primary">Place Order</Button>
                        </Col>
                    </Row>
                </div>

            </React.Fragment >
        )
    }
}
export default withRouter(OrderDetails)