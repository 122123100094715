import React from 'react';
import { withRouter } from 'react-router';
import './BundlesListing.scss';
import { Row, Col } from 'antd';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import Listings from './Listings/Listings';
import Filter from './Filter/Filter';

class BundlesListing extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners bundles">
                    <div className="container mx-auto">
                        <h1>Bundles</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">

                        <BreadCrumbs {...this.props} navigations={[{ name: 'Home', link: '/' }, { name: 'Bundles', link: '/bundles' }, { name: 'Choose by Room Type', link: '/bundles' }, { name: 'Living room', link: '/bundles-listing' }]} />
                       
                        <Row gutter={[12, 20]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Filter />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 17 }} lg={{ span: 17 }}>
                                <Listings />
                                <Listings />
                                <Listings />
                            </Col>
                        </Row>
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
export default withRouter(BundlesListing)