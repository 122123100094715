import React from 'react';
import { withRouter } from 'react-router';
import Slider from "react-slick";
import './ProductSldier.scss';

class ProductSldier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        return (
            <React.Fragment>
                {/* <!--product Slider section starts--> */}
                <div className="product-slider">
                    {/* <!--Slider Item starts--> */}
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        arrows={false}
                        fade={true}
                    >

                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/furnished-room.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-2.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-3.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-4.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-5.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-6.webp')} alt="" />
                        </div>

                    </Slider>


                    <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        slidesToShow={6}
                        swipeToSlide={true}
                        focusOnSelect={true}
                    >

                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/furnished-room.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-2.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-3.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-4.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-5.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/property-listings/gallery-6.webp')} alt="" />
                        </div>

                    </Slider>

                </div>
                {/* <!--product Slider section ends--> */}
            </React.Fragment>

        )
    }
}
export default withRouter(ProductSldier)