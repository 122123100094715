import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Collapse } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './OpenOrders.scss';
import SideNavigation from '../MyCart/SideNavigation/SideNavigation';
import OrderDetails from './OrderDetails';

const { Panel } = Collapse;

class OpenOrders extends React.Component {

    render() {
        const genExtra = () => (
            <div className="action-items"
                onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}
            >
                <div className="edit">
                    <Button type="link"><i className="flaticon-edit" /></Button>
                </div>
                <div className="total-items">
                    <span>(3 items)</span>
                </div>
                <div className="clone-and-delete">
                    <Button type="link"><i className="flaticon-copy" /></Button>
                    <Button type="link"><i className="flaticon-delete-1" /></Button>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <section className="open-orders">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <SideNavigation />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom20">Open Orders</h2>
                                    <div className="open-orders-list">
                                        <Collapse
                                            defaultActiveKey={['1']}
                                            expandIconPosition='right'
                                            expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                                            ghost
                                        >
                                            <Panel header="House Number 1" key="1" extra={genExtra()}>
                                                <OrderDetails />
                                            </Panel>
                                            <Panel header="House Number 2" key="2" extra={genExtra()}>
                                                <OrderDetails />

                                            </Panel>
                                            <Panel header="House Number 3" key="3" extra={genExtra()}>
                                                <OrderDetails />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(OpenOrders)