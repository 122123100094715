import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import './Listings.scss';
import { Row, Col, Image, Button } from 'antd';

class BundlesListing extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="property-listing">
                    <Row gutter={[12, 20]} className="property-details">
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <img src={require('../../../assets/images/bundles/property-listings/furnished-room.webp')} alt="Prospect Velvet Loveseat Navy blue" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <div className="heading">
                            <h3>Prospect Velvet Loveseat Navy blue</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <Link className="make-favourite"><i className="fa fa-heart-o" /></Link>
                            </div>
                            <div className="gallery-images">
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-1.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-2.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-3.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-4.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-5.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-6.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-7.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-8.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-9.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-10.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-11.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-12.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-12.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-12.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-12.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-12.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-12.webp')} alt="" />
                                <Image src={require('../../../assets/images/bundles/property-listings/gallery-12.webp')} alt="" />
                            </div>
                        </Col>
                    </Row>
                    {/* property details ends */}
                    <Row className="pricing-details">
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <div className="rent-details">Rent <span>: $ 875/mon</span></div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <div className="shipping"><i class="fa fa-truck" /> ships in 4 days</div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <Button type="secondary">View Details</Button>
                            <Button type="primary">Add To Cart</Button>
                        </Col>
                    </Row>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(BundlesListing)