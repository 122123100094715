import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import './QuickLinks.scss';

class QuickLinks extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <div className="about-quick-links">
                    <ul>
                        <li>
                            <Link to="/about-us">About Us</Link>
                        </li>
                        <li>
                            <Link to="/culture">Culture</Link>
                        </li>
                        <li>
                            <Link to="">Our Commitment</Link>
                        </li>
                        <li>
                            <Link to="">Career</Link>
                        </li>
                    </ul>
                </div>

            </React.Fragment >
        )
    }
}
export default withRouter(QuickLinks)