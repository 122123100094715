import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class SideNavigation extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="cart-side-navigation">
                    <ul>
                        <li><Link to="/my-cart">My Cart</Link></li>
                        <li><Link to="/my-wishlist">My Wishlist</Link></li>
                        <li><Link to="/recently-viewed">Recently Viewed</Link></li>
                        <li><Link to="/open-orders">Open Orders</Link></li>
                    </ul>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(SideNavigation)