import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import SideNavigation from '../MyCart/SideNavigation/SideNavigation';

class MyWishlist extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <section className="my-cart-details">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <SideNavigation />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom20">My Wishlist</h2>
                                    {/* my wishlist ends here */}
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="furniture-grid">
                                                <div className="details">
                                                    <div className="image">
                                                        <img src={require("../../assets/images/furniture/listings/furniture-1.webp")} alt="" />
                                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                    </div>
                                                    <h4>Colvill Solid Wood Arm Chair</h4>
                                                    <div className="other-details">
                                                        <div className="rent">
                                                            <span className="title">Rent</span>
                                                            <span className="price">$ 12/mon</span>
                                                        </div>
                                                        <div className="shipping">
                                                            <span>ships in 4 days</span>
                                                        </div>
                                                        <div className="quick-view">
                                                            <Button type="secondary" onClick={() => this.setState({ quickView: true })} >Quick View</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="furniture-grid">
                                                <div className="details">
                                                    <div className="image">
                                                        <img src={require("../../assets/images/furniture/listings/furniture-2.webp")} alt="" />
                                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                    </div>
                                                    <h4>Hugo 2 Seater Sofa - White</h4>
                                                    <div className="other-details">
                                                        <div className="rent">
                                                            <span className="title">Rent</span>
                                                            <span className="price">$ 12/mon</span>
                                                        </div>
                                                        <div className="shipping">
                                                            <span>ships in 4 days</span>
                                                        </div>
                                                        <div className="quick-view">
                                                            <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="furniture-grid">
                                                <div className="details">
                                                    <div className="image">
                                                        <img src={require("../../assets/images/furniture/listings/furniture-3.webp")} alt="" />
                                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                    </div>
                                                    <h4>Hugo 4 Seater L shape Sofa</h4>
                                                    <div className="other-details">
                                                        <div className="rent">
                                                            <span className="title">Rent</span>
                                                            <span className="price">$ 12/mon</span>
                                                        </div>
                                                        <div className="shipping">
                                                            <span>ships in 4 days</span>
                                                        </div>
                                                        <div className="quick-view">
                                                            <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="furniture-grid">
                                                <div className="details">
                                                    <div className="image">
                                                        <img src={require("../../assets/images/furniture/listings/furniture-3.webp")} alt="" />
                                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                    </div>
                                                    <h4>Hugo 4 Seater L shape Sofa</h4>
                                                    <div className="other-details">
                                                        <div className="rent">
                                                            <span className="title">Rent</span>
                                                            <span className="price">$ 12/mon</span>
                                                        </div>
                                                        <div className="shipping">
                                                            <span>ships in 4 days</span>
                                                        </div>
                                                        <div className="quick-view">
                                                            <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* my wishlist ends here */}
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(MyWishlist)