import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

class SiderMenu extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <div className="cart-side-navigation">
                    <ul>
                        <li>
                            <Link to="./live-orders">Live Orders</Link>
                        </li>
                        <li>
                            <Link to="./undelivered-orders">Undelivered Orders</Link>
                        </li>
                        <li>
                            <Link to="/order-history">Order History</Link>
                        </li>
                        <li>
                            <Link to="./my-inventory">My Inventory</Link>
                        </li>
                        <li>
                            <Link to="">Payment</Link>
                        </li>
                    </ul>
                </div>

            </React.Fragment >
        )
    }
}
export default withRouter(SiderMenu)