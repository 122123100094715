import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';

class Furniture extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners furniture">
                    <div className="container mx-auto">
                        <h1>Furniture</h1>
                        <p>Our Furniture Includes Luxurious Chairs, Sturdy Desks, Chic Wardrobes, And Comfortable Beds.</p>
                        <p>You Will Find Pre-Selected, Tasteful Furniture For Your Kitchen, Living Room, Dining Room, And Bedroom. </p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">
                        <div className="choose-room-type type-1">
                            <h2>Choose by Room Type</h2>
                            <Row gutter={[12, 20]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Link to="/furniture-listing">
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/room-type/living-room.webp')} alt="Living Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Living Room</div>
                                                <div className="total-bundles">156 items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/room-type/bed-room.webp')} alt="Bed Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Bed Room</div>
                                                <div className="total-bundles">126 items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/room-type/dining-room.webp')} alt="Dining Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Dining Room</div>
                                                <div className="total-bundles">146 items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/room-type/office.webp')} alt="Office" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Office</div>
                                                <div className="total-bundles">136 items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/room-type/kids-room.webp')} alt="Kids Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Kids Room</div>
                                                <div className="total-bundles">112 items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/room-type/outdoor.webp')} alt="Outdoor" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Outdoor</div>
                                                <div className="total-bundles">85 items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/room-type/accessories.webp')} alt="Accessories" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Accessories</div>
                                                <div className="total-bundles">235 items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                            </Row>
                        </div>
                        {/* choose by room type ends */}
                        <div className="choose-room-type">
                            <h2>Choose by Furniture Type</h2>
                            <Row gutter={[12, 20]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/furniture-type/bed.webp')} alt="Bed Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Bed Room</div>
                                                <div className="total-bundles">12 Items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/furniture-type/sofa.webp')} alt="Sofa" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Sofa</div>
                                                <div className="total-bundles">12 Items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/furniture-type/dining-table.webp')} alt="Dining Table" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Dining Table</div>
                                                <div className="total-bundles">12 Items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/furniture-type/cupboards.webp')} alt="Cupboards" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Cupboards</div>
                                                <div className="total-bundles">12 Items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/furniture-type/chairs.webp')} alt="Chairs" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Chairs</div>
                                                <div className="total-bundles">12 Items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/furniture/furniture-type/side-table.webp')} alt="Side Table" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Side Table</div>
                                                <div className="total-bundles">12 Items</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                            </Row>
                        </div>
                        {/* choose by Apartment type ends */}
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
export default withRouter(Furniture)