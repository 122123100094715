import React from 'react';
import { withRouter } from 'react-router';
import './BundlesDetailedPage.scss';
import { Row, Col, Button } from 'antd';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import ProductSldier from './ProductSldier/ProductSlider';
import InputBox from '../../Components/InputBox/InputBox';
import GetRefund from './GetRefund/GetRefund';
import RecommendedProducts from './RecommendedProducts/RecommendedProducts';
import SimilarProducts from './SimilarProducts/SimilarProducts';

class BundlesDetailedPage extends React.Component {
    constructor() {
        super()
        this.state = {
            zipCode: '',
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners bundles">
                    <div className="container mx-auto">
                        <h1>Bundles</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-bottom-wave light-bg">
                    <div className="container mx-auto">

                        <BreadCrumbs {...this.props} navigations={[{ name: 'Home', link: '/' }, { name: 'Bundles', link: '/bundles' }, { name: 'Choose by Room Type', link: '/bundles' }, { name: 'Living room', link: '/bundles-listing' }, { name: 'Living Room Bundle', link: '/bundles-detailed-page' }]} />

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <ProductSldier />
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2 }}>
                                <div className="detailed-view">
                                    <div className="title">
                                        <h2>Prospect Velvet Loveseat Navy blue</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                    <div className="price">$ 270/m</div>
                                    <div className="overview">
                                        <ul>
                                            <li>1  X  Carter Occasional Chair Light Gray</li>
                                            <li>1  X  Article Torch Floor Lamp Black</li>
                                            <li>1  X  Article Clarus Side Table Walnut</li>
                                            <li>4  X  Pyramid Dining Chair Black</li>
                                            <li>3  X  Proclaim Metal Table Lamp Black</li>
                                            <li>1  X  Origin Wood Nightstand Walnut And White</li>
                                            <li>1  X  Origin 4-Drawer Chest Walnut And White</li>
                                            <li>1  X  Anya Queen Bed Beige</li>
                                            <li>1  X  Lode Rectangle Wood Dining Table White</li>
                                        </ul>
                                    </div>
                                    <div className="check-availaility">
                                        <h3>Enter ZIP code to check if we deliver to your area</h3>
                                        <form>
                                            <div className="form-fields">
                                                <InputBox
                                                    placeholder="Enter Zipcode"
                                                    id="zipCode"
                                                    value={this.state.zipCode}
                                                    onChangeText={this.onChangeText}
                                                    type="text"
                                                    optional="true"
                                                />

                                                <Button type="link">Check Availability</Button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="action-button margin-top20">
                                        <Button type="secondary">Add to wishlist</Button>
                                        <Button type="primary">Add to cart</Button>
                                        <Button type="link">Check Availability Calendar</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 12, order: 4 }} lg={{ span: 12, order: 4 }}>
                                <GetRefund />
                                <GetRefund />
                            </Col>

                            <Col xs={{ span: 24, order: 4 }} sm={{ span: 12, order: 4 }} md={{ span: 12, order: 3 }} lg={{ span: 12, order: 3 }}>
                                <RecommendedProducts />
                                <RecommendedProducts />
                                <RecommendedProducts />
                            </Col>

                            <Col xs={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }} md={{ span: 24, order: 5 }} lg={{ span: 24, order: 5 }}>
                                 <div className="title margin-top40">
                                    <h4>Similar products</h4>
                                    <p>Save money and time and also save on shipping expenses by ordering the bundles.</p>
                                </div>
                                <SimilarProducts />
                            </Col>

                        </Row>
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
export default withRouter(BundlesDetailedPage)