import React from 'react';
import { withRouter } from 'react-router';
import './Inspirations.scss';

class Inspirations extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners inspirations">
                    <div className="container mx-auto">
                        <h1>Inspirations</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="furniture-inspirations">
                    <div className="container mx-auto">
                        <div className="inspirations-list">
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-1.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-2.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-3.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-4.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-5.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-6.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-7.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-8.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-9.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-10.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-11.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-12.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-13.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-14.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-15.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                            <div className="items">
                                <div className="details">
                                    <img src={require('../../assets/images/inspirations/inspirations-16.webp')} alt="Inspirations" />
                                </div>
                            </div>
                            {/* items ends here */}
                        </div>
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
export default withRouter(Inspirations)