import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import Slider from "react-slick";

const { Text } = Typography;

const OffersForyouslidersettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]
};

class OffersForyou extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="offers-foryou">
                    {/* <!--Slider Item starts--> */}
                    <Slider {...OffersForyouslidersettings}>
                        <div className="item">
                            <div className="furniture-grid">
                                <div className="details">
                                    <div className="image">
                                        <img src={require("../../../assets/images/product-popularity/sofa-1.webp")} alt="Colvill Solid Wood Arm Chair" />
                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                    </div>
                                    <h4>Colvill Solid Wood Arm Chair</h4>
                                    <div className="other-details">
                                        <div className="rent">
                                            <span className="title">Rent</span>
                                            <Text delete><span className="price">$ 12/mon</span></Text>
                                            <span className="sale-price">$ 12/mon</span>
                                        </div>
                                        <div className="shipping">
                                            <span>ships in 4 days</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* item ends */}

                        <div className="item">
                            <div className="furniture-grid">
                                <div className="details">
                                    <div className="image">
                                        <img src={require("../../../assets/images/product-popularity/sofa-2.webp")} alt="Colvill Solid Wood Arm Chair" />
                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                    </div>
                                    <h4>Annette Cafe Chair </h4>
                                    <div className="other-details">
                                        <div className="rent">
                                            <span className="title">Rent</span>
                                            <Text delete><span className="price">$ 12/mon</span></Text>
                                            <span className="sale-price">$ 12/mon</span>
                                        </div>
                                        <div className="shipping">
                                            <span>ships in 4 days</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* item ends */}

                        <div className="item">
                            <div className="furniture-grid">
                                <div className="details">
                                    <div className="image">
                                        <img src={require("../../../assets/images/product-popularity/sofa-3.webp")} alt="Miranda 3 Seater Sofa" />
                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                    </div>
                                    <h4>Miranda 3 Seater Sofa</h4>
                                    <div className="other-details">
                                        <div className="rent">
                                            <span className="title">Rent</span>
                                            <Text delete><span className="price">$ 12/mon</span></Text>
                                            <span className="sale-price">$ 12/mon</span>
                                        </div>
                                        <div className="shipping">
                                            <span>ships in 4 days</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* item ends */}

                        <div className="item">
                            <div className="furniture-grid">
                                <div className="details">
                                    <div className="image">
                                        <img src={require("../../../assets/images/product-popularity/sofa-4.webp")} alt="Brisco End Table" />
                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                    </div>
                                    <h4>Brisco End Table</h4>
                                    <div className="other-details">
                                        <div className="rent">
                                            <span className="title">Rent</span>
                                            <Text delete><span className="price">$ 12/mon</span></Text>
                                            <span className="sale-price">$ 12/mon</span>
                                        </div>
                                        <div className="shipping">
                                            <span>ships in 4 days</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* item ends */}

                        <div className="item">
                            <div className="furniture-grid">
                                <div className="details">
                                    <div className="image">
                                        <img src={require("../../../assets/images/product-popularity/sofa-1.webp")} alt="Colvill Solid Wood Arm Chair" />
                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                    </div>
                                    <h4>Colvill Solid Wood Arm Chair</h4>
                                    <div className="other-details">
                                        <div className="rent">
                                            <span className="title">Rent</span>
                                            <Text delete><span className="price">$ 12/mon</span></Text>
                                            <span className="sale-price">$ 12/mon</span>
                                        </div>
                                        <div className="shipping">
                                            <span>ships in 4 days</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* item ends */}

                        <div className="item">
                            <div className="furniture-grid">
                                <div className="details">
                                    <div className="image">
                                        <img src={require("../../../assets/images/product-popularity/sofa-1.webp")} alt="Colvill Solid Wood Arm Chair" />
                                        <Link className="favourite"><i className="fa fa-heart" /></Link>
                                    </div>
                                    <h4>Colvill Solid Wood Arm Chair</h4>
                                    <div className="other-details">
                                        <div className="rent">
                                            <span className="title">Rent</span>
                                            <Text delete><span className="price">$ 12/mon</span></Text>
                                            <span className="sale-price">$ 12/mon</span>
                                        </div>
                                        <div className="shipping">
                                            <span>ships in 4 days</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* item ends */}

                    </Slider>
                    {/* <!--Slider Item ends--> */}
                </div>

            </React.Fragment>
        )
    }
}
export default withRouter(OffersForyou)