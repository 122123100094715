import React from 'react';
import { withRouter } from 'react-router';
import './ContactUs.scss';
import { Row, Col } from 'antd';
import { Select } from 'antd';
import Dropdwn from './Dropdwn';

class ContactUs extends React.Component {

    render() {
        return (
            <React.Fragment>
             <div class="container space">

             <div class="title">
                <h1>Contact Us </h1>
                </div> <br></br>
  <div class="row a" >
    <div class="col-sm-4">
    <label for="fname">Name</label>
    <input type="text" id="fname" name="firstname" placeholder="Your name.."></input>
            
      </div>
      <div class="col-sm-4">
      <label for="mail">Email</label><br></br>
                <input class="inbox" type="mail" id="mail" name="email" placeholder="Email id.."></input> 
        </div>
<br></br>
        <div class="col-sm-4">
      <label for="tel">Phone</label><br></br>
                <input class="tel inbox" type="phone" id="phone" name="phone" placeholder="Enter number"></input> 
        </div>
     <br></br>

     <div class="col-sm-4">
      <label for="message">Send Comments</label><br></br>
                <input type="texts" id="message" name="comments" placeholder="Type comments.."></input> 
        </div>
<br></br>
<input type="submit" value="Submit"></input>

<div className="contactvalet">
        <div className="contactval">
               <img src={require('../assets/images/contactvalet1.jpg')} alt="google signin" />
        </div> 
               </div>
<br></br>
<br></br>
               

      {/*<div class="col-sm-4">
      <label for="Select Sector">Select Sector</label>
    <select id="Select Sector" name="Select Sector" placeholder="Select">
    <option value="Select">Select</option>
      <option value="Residential">Residential</option>
      <option value="Commercial">Commercial</option>
    <option value="Hospitality">Hospitality</option>
      <option value="Industrial">Industrial</option>
      <option value="Healthcare">Healthcare</option> <br></br>
    </select><br></br>
    </div>
   
      <br></br>

    <input type="submit" value="Submit"></input>
  
             {/* <label for="fname">Name</label>
             <input type="text" id="fname" name="firstname" placeholder="Your name.."></input>
             <label for="mail">Email</label><br></br>
             <input type="mail" id="mail" name="email" placeholder="Email id.."></input> <br></br>
            <label for="number">Phone</label>         
          <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"></input>
            <input type="" id="number" name="number" placeholder="Enter number..">-->
            <label for="number">Phone</label> 
            <label for="phone">Enter your phone number:</label>
<input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"></input>

             <label for="Select Sector">Select Sector</label>
    <select id="Select Sector" name="Select Sector" placeholder="Select">
    <option value="Select">Select</option>
      <option value="Residential">Residential</option>
      <option value="Commercial">Commercial</option>
    <option value="Hospitality">Hospitality</option>
      <option value="Industrial">Industrial</option>
      <option value="Healthcare">Healthcare</option> <br></br>
    </select><br></br>

    <input type="submit" value="Submit"></input>
        </div></div> */}

            </div>
      </div>
      </React.Fragment>);
      

            
    }
  }

export default withRouter(ContactUs) 