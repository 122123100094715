import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import './PersonalDetails.scss';
import Address from './Address';
import Cards from './Cards';
import PersonalDetailsNavigation from './PersonalDetailsNavigation';

class PersonalDetails extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="personal-details">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <PersonalDetailsNavigation />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom10">Personal Details</h2>
                                    <div className="personal-details card margin-bottom20">
                                        <Row>
                                            <Col xs={{ span: 24 }} sm={{ span: 19 }} md={{ span: 19 }} lg={{ span: 19 }}>

                                                <div className="list">
                                                    <div className="label">Full Name</div>
                                                    <div className="content">Robert Jhonson</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">Mobile No.</div>
                                                    <div className="content">+44 79238 37126</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">Email Address</div>
                                                    <div className="content">robert.jhonson12@hotmail.uk</div>
                                                </div>
                                            </Col>
                                            <Col className="text-right" xs={{ span: 24 }} sm={{ span: 5 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                                <Link to="/edit-personal-details"><i className="flaticon-edit" /></Link>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* personal details ends */}

                                    <h2 className="margin-bottom10">Address Details</h2>
                                    <div className="address-details card margin-bottom20">
                                        <h4>Saved Address</h4>
                                        <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Address />
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Address />
                                            </Col>
                                        </Row>
                                        <Button className="margin-top20" type="link"><PlusOutlined /> Add New Address</Button>
                                    </div>
                                    {/* address details ends */}

                                    <h2 className="margin-bottom10">Card Details</h2>
                                    <div className="address-details card margin-bottom20">
                                        <h4>Saved Cards</h4>
                                        <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Cards />
                                            </Col>
                                            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Cards />
                                            </Col>
                                        </Row>
                                        <Button className="margin-top20" type="link"><PlusOutlined /> Add New Card</Button>
                                    </div>
                                    {/* address details ends */}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(PersonalDetails)