import React from 'react';
import { withRouter } from 'react-router';
import OffersSlider from './OffersSlider/OffersSlider';
import OffersForyou from './OffersForyou/OffersForyou';
import OffersonCard  from './OffersonCard/OffersonCard';
import './Offers.scss';

class Offers extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners offers ">
                    <div className="container mx-auto">
                        <h1>Offers</h1>
                        <p>Our Furniture Includes Luxurious Chairs, Sturdy Desks, Chic Wardrobes, And Comfortable Beds.</p>
                        <p>You Will Find Pre-Selected, Tasteful Furniture For Your Kitchen, Living Room, Dining Room, And Bedroom. </p>
                    </div>
                </section>
                {/* page banners */}

                <div className="inner-pages top-left-wave light-bg">
                    <div className="container mx-auto">
                        <OffersSlider />

                        <section className="offers-for-you">
                            <h2>Offers for you</h2>
                            <OffersForyou />
                        </section>

                        <section className="special-deals">
                            <h2>Special Deals</h2>
                            <OffersForyou />
                        </section>

                        <section className="special-deals">
                            <h2>Offers On Cards</h2>
                            <OffersonCard />
                        </section>
                    </div>
                </div>


            </React.Fragment >
        )
    }
}
export default withRouter(Offers)