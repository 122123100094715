import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import '../MyDashboard.scss';
import SiderMenu from '../SiderMenu';
import InputBox from '../../../Components/InputBox/InputBox';

class OrderHistory extends React.Component {
    constructor() {
        super()
        this.state = {
            searcbyOrderno: '',
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="my-dashboard margin-bottom20">
                            <h1>My Dashboard</h1>
                        </div>
                        <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <SiderMenu />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                <div className="card my-orders light-color">
                                    <Row>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h2>Order History</h2>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="form-fields">
                                                <InputBox
                                                    placeholder="Search by Product name or order no."
                                                    id="searcbyOrderno"
                                                    value={this.state.searcbyOrderno}
                                                    onChangeText={this.onChangeText}
                                                    type="text"
                                                    optional="true"
                                                    suffix={"fa fa-search"}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    {/* list starts here */}
                                    <div className="card">
                                        <Row>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                                <div className="order-details">
                                                    <div className="order-no">Order No. : <span>1234567890</span></div>
                                                    <div className="order-no">Total Items : <span>8</span></div>
                                                    <div className="order-no">Amount Paid : <span className="price">$180</span></div>
                                                </div>
                                                <div className="address-details">
                                                    <div className="label">Address Details :</div>
                                                    <div className="content">
                                                        <span>14 Darklake View, Estover</span>
                                                        <span>New York, 12401</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                                <div className="action-buttons">
                                                    <Button type="secondary">View Order Details</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* list ends here */}

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(OrderHistory)