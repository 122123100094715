import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import './AboutUs.scss';
import QuickLinks from '../QuickLinks/QuickLinks';


class AboutUs extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="about-banners">
                    <img src={require('../../assets/images/aboutusimage.jpg')} alt="About Us" />
                </section>
                {/* page banners */}

            
                    <div className="container mx-auto">

                     

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                               <div className='aboutuscontent'>
                                <h3>About Us</h3>
                                <p> Valet Parking is the World's first Digital Platform for all Valet Parking needs Worldwide. <br></br>
            Valet Parking offers Automated Valet Parking Service right from choosing the Valet, <br></br>
             Track your precious car and also ensures that it is safely parked in a certified parking<br></br> lot.
             Valet Parking has partnered with the Best Technology brand to offer the safety of<br></br> your car and 
             also to ensure that your car keys are handed over to a certified valet.<br></br>
             Valet Parking can be used largely by Sectors in Hospitality, Retail,
             Luxury Retail, Malls,<br></br> Cineplexes, Serviced Residences, Clubs, Events, Weddings and much more.
 </p>
 </div>                     
                            </Col>

                            
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                           <div className='aboutusimg'>
                                <img src={require('../../assets/images/valerparking2.jpg')} alt="About Us" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                 

            </React.Fragment> )
        





    }
}
export default withRouter(AboutUs)


