import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Collapse, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './MyCart.scss';
import SideNavigation from './SideNavigation/SideNavigation';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderSummary from './OrderSummary/OrderSummary';
import Delivery from './StagingDetails/Delivery';
import Pickup from './StagingDetails/Pickup';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const genExtra = () => (
    <div className="total-items">
        <span>(3 items)</span>
    </div>
);

class MyCart extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <section className="my-cart-details">
                            <Row gutter={[{ xs: 10, sm: 10, md: 10, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <SideNavigation />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 13 }} lg={{ span: 13 }}>
                                    <div className="my-cart-tabs">
                                        {/* accordian view starts */}
                                        <Collapse
                                            defaultActiveKey={['1']}
                                            expandIconPosition='right'
                                            expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                                            ghost
                                        >
                                            <Panel header="Order Summary" key="1" extra={genExtra()}>
                                                <OrderSummary />
                                            </Panel>
                                            <Panel header="Staging Details" key="2">
                                                <h3>Select Option</h3>
                                                <p>Pickup option is not available for this order, as it includes staging depot products too.</p>
                                                <Tabs defaultActiveKey="delivery">
                                                    <TabPane tab="Delivery" key="delivery">
                                                        <Delivery />
                                                    </TabPane>
                                                    <TabPane tab="Pickup" key="pickup">
                                                        <Pickup />
                                                    </TabPane>
                                                </Tabs>

                                            </Panel>
                                            <Panel header="Payment" key="3">

                                            </Panel>
                                        </Collapse>
                                    </div>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <OrderDetails />
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(MyCart)