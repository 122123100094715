import React from 'react';
import { withRouter } from 'react-router';
import { Button, Calendar } from 'antd';
import InputBox from '../../../Components/InputBox/InputBox';

class Pickup extends React.Component {
    constructor() {
        super()
        this.state = {
            defaultActiveKey: []
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="pickup-details">

                    <div className="calendar">
                        <Calendar
                            local="month"
                            mode="month"
                        />
                    </div>

                    <div className="action-buttpm text-right">
                        <Button type="primary">Proceed</Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Pickup)