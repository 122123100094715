import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Divider, Checkbox, Slider, Button } from 'antd';
import DateBox from '../../../Components/DateBox/DateBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
const marks = {
    10: '$ 10',
    500: '$ 500'
};
class Filter extends React.Component {

    constructor() {
        super()
        this.state = {
            fromDate: '',
            durations: [],
            defaultActiveKey: []
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    render() {
        return (
            <React.Fragment>
                <div className="Filter">
                    <Row className="filter-heading">
                        <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <h1><i className="fa fa-filter" /> Filters</h1>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <Button type="secondary">Reset</Button>
                        </Col>
                    </Row>
                    {/* filter heading ends */}

                    <Divider />

                    <div className="filter-sections">
                        <h4>View By Availability</h4>
                        <p>Look For Items Available During This Period (Your Rental Duration)</p>
                        <div className="form-fields">
                            <DateBox
                                label="From"
                                placeholder="12/08/20"
                                id="fromDate"
                                value={this.state.fromDate}
                                onChangeText={this.onChangeText}
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <SelectBox
                                label="Durations"
                                id="durations"
                                value={this.state.durations}
                                options={this.state.durations}
                                onChangeText={this.onChangeText}
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <Checkbox>Show Only Available Products</Checkbox>
                        </div>
                    </div>

                    <Divider dashed />
                    <div className="filter-sections">
                        <h4>Price Range</h4>
                        <div className="price-range">
                            <Slider
                                marks={marks}
                                min={0}
                                max={500}
                                defaultValue={10}
                            />
                        </div>
                    </div>

                    <Divider dashed  />

                    <div className="filter-sections">
                        <h4>Choose Bundle</h4>
                        <Button type="secondary">Standard Bundle</Button>
                        <Button type="secondary">Customize Bundle</Button>
                    </div>

                    <Divider dashed />

                    <div className="filter-sections">
                        <h4>Choose Apartment Type</h4>
                        <div className="options-type">
                            <div className="options-list">
                                <Checkbox>Studio</Checkbox>
                                <Checkbox>1 Bed Room</Checkbox>
                                <Checkbox>2 Bed Room</Checkbox>
                            </div>
                        </div>
                    </div>

                    <Divider />

                    <div className="filter-sections">
                        <h4>Choose Room Type</h4>
                        <div className="options-type">
                            <div className="options-list">
                                <Checkbox>Living Room</Checkbox>
                                <Checkbox>Bed Room</Checkbox>
                                <Checkbox>Dining Room</Checkbox>
                                <Checkbox>Kid’s Room</Checkbox>
                                <Checkbox>Kitchen</Checkbox>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Filter)