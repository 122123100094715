import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";

const Mainslidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="quikjets-banner">
          <div className="quikjets-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item empty-legs-flight">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2>Hotel</h2>
                            <p> 
                                
                            </p>

                         {/* <div className="action-buttons">
                              <Link to="" className="btn btn-standard">
                               
                              </Link>
                             </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                
               
                {/* <!--Slider Item ends--> */}

                <div className="item memership">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2>Weddings</h2>
                           
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
               {/*} <div className="item world-class">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2></h2>
                            <p>
                             
                            </p>
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                          </div>*/}
               {/* <!--Slider Item ends--> */}

                <div className="item world">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2>Malls</h2>
                           
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                 {/* <!--Slider Item ends--> */}

                 <div className="item slider">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Healthcare </h2>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item restuarant">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="restua">
                            <h2> Restuarant </h2>
                           
                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item lounge">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="loun">
                            <h2> Lounge </h2>
                           
                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item theatre">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Theatre </h2>
                           
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item hospitality">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="hos">
                            <h2> Hospitality </h2>
                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              
                <div className="item Jewellerymart">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="jewe">
                            <h2> Jewellery Mart </h2>
                           
                            </div>
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>


                <div className="item casino">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Casino </h2>
                           
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item gala">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Gala Events</h2>
                           
                            
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

          

                <div className="item concerts">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Concerts </h2>
                           
                            
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item conference">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Conference </h2>
                           
                            
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item events">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Events </h2>
                           
                            
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                
                <div className="item airport">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Airport </h2>
                           
                            
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item Exhibition">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Exhibition </h2>
                           
                            
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item Government">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="gov">
                            <h2> Government Offices </h2>
                           
                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item salon">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="salonspa">
                            <h2> Salon and Spa </h2>
                           
                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item realestate">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="real">
                            <h2> Real Estate </h2>
                           
                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="item service">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <div className="serv">
                            <h2> Service Offices </h2>
                           
                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                
                 {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
