import React from 'react';
import { withRouter } from 'react-router';
import './Testimonials.scss';
import Slider from "react-slick";

const testimonalsettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

class Testimonials extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="testimonials">
                    <div className="section-title">
                        <h1>Testimonials</h1>
                    </div>

                    <div className="testimonials-container">
                        <div className="testimonials-list">
                            <Slider {...testimonalsettings}>
                                <div className="list">
                                    <div className="details">
                                        <div className="profile">
                                            <div className="image">
                                                <img src={require("../../../assets/images/testimonials/testimonial-1.webp")} alt="Sandra Rubio" />
                                            </div>
                                            <div className="profile-details">
                                                <h4>Sandra Rubio</h4>
                                                <p>Custom casa home stager </p>
                                            </div>
                                        </div>
                                        <div className="customer-message">
                                            <p>As a Home stager, I like to work with Staging Depot products. Easy to order, to transport and to move, as a complete home furnishing fits in the trunk of my car. With timeless designs and the large choice of fabrics, i can create a different atmosphere each time. Appropriate for each target group.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="list">
                                    <div className="details">
                                        <div className="profile">
                                            <div className="image">
                                                <img src={require("../../../assets/images/testimonials/testimonial-2.webp")} alt="Casa & Co." />
                                            </div>
                                            <div className="profile-details">
                                                <h4>Casa & Co.</h4>
                                                <p>Home staging</p>
                                            </div>
                                        </div>
                                        <div className="customer-message">
                                            <p>The cartboard furniture of Staging Depot has given us creative solution in empty houses that lacked soul. They are versatile, practical furniture, easy assembly, and high impact. Management, from ordering, tracking, and delivery are excellent</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="list">
                                    <div className="details">
                                        <div className="profile">
                                            <div className="image">
                                                <img src={require("../../../assets/images/testimonials/testimonial-3.webp")} alt="Javier Suarez" />
                                            </div>
                                            <div className="profile-details">
                                                <h4>Javier Suarez</h4>
                                                <p>Ya Home staging</p>
                                            </div>
                                        </div>
                                        <div className="customer-message">
                                            <p>As a Home stager, I like to work with Staging Depot products. Easy to order, to transport and to move, as a complete home furnishing fits in the trunk of my car. With timeless designs and the large choice of fabrics, i can create a different atmosphere each time. Appropriate for each target group.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="list">
                                    <div className="details">
                                        <div className="profile">
                                            <div className="image">
                                                <img src={require("../../../assets/images/testimonials/testimonial-1.webp")} alt="Sandra Rubio" />
                                            </div>
                                            <div className="profile-details">
                                                <h4>Sandra Rubio</h4>
                                                <p>Custom casa home stager </p>
                                            </div>
                                        </div>
                                        <div className="customer-message">
                                            <p>As a Home stager, I like to work with Staging Depot products. Easy to order, to transport and to move, as a complete home furnishing fits in the trunk of my car. With timeless designs and the large choice of fabrics, i can create a different atmosphere each time. Appropriate for each target group.</p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section >
            </React.Fragment >
        )
    }
}
export default withRouter(Testimonials)