import React from 'react';
import { withRouter } from 'react-router';
import { Button, Checkbox, Divider } from 'antd';
import InputBox from '../../../Components/InputBox/InputBox';

class Delivery extends React.Component {
    constructor() {
        super()
        this.state = {
            name: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            zipCode: '',
            defaultActiveKey: []
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="staging-details">
                    <h3>Staging Address</h3>
                    <div className="personal-data">
                        <div className="form-fields">
                            <InputBox
                                label="Name"
                                id="name"
                                value={this.state.name}
                                onChangeText={this.onChangeText}
                                type="text"
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="Address Line 1"
                                id="addressLine1"
                                value={this.state.addressLine1}
                                onChangeText={this.onChangeText}
                                type="text"
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="Address Line 2"
                                id="addressLine2"
                                value={this.state.addressLine2}
                                onChangeText={this.onChangeText}
                                type="text"
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="City"
                                id="city"
                                value={this.state.city}
                                onChangeText={this.onChangeText}
                                type="text"
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="Zip Code"
                                id="zipCode"
                                value={this.state.zipCode}
                                onChangeText={this.onChangeText}
                                type="number"
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <Checkbox>Save Address</Checkbox>
                        </div>
                    </div>
                    <Divider>OR</Divider>
                    <div className="saved-address-details">
                        <h5>Choose from below address</h5>
                        <div className="saved-address">
                            <div className="list">
                                <div className="details">
                                    <p>Address 1</p>
                                </div>
                            </div>
                            {/* list ends */}
                            <div className="list">
                                <div className="details">
                                    <p>Address 2</p>
                                </div>
                            </div>
                            {/* list ends */}
                        </div>
                    </div>
                    <Divider />
                    <div className="select-delivery-details">
                        <h5>Select Delivery Date</h5>
                        <div className="delivery-date-time">
                            <div className="list">
                                <div className="date-time selected">21-July-2021</div>
                            </div>
                            <div className="list">
                                <div className="date-time">21-July-2021</div>
                            </div>
                            <div className="list">
                                <div className="date-time">21-July-2021</div>
                            </div>
                            <div className="list">
                                <div className="date-time">21-July-2021</div>
                            </div>
                            <div className="list">
                                <div className="date-time">21-July-2021</div>
                            </div>
                        </div>
                    </div>
                    {/* select date ends */}
                    <div className="select-delivery-details">
                        <h5>Select Time</h5>
                        <div className="delivery-date-time">
                            <div className="list">
                                <div className="date-time selected">10am-12pm</div>
                            </div>
                            <div className="list">
                                <div className="date-time">2pm-4pm</div>
                            </div>
                            <div className="list">
                                <div className="date-time">5pm-6pm</div>
                            </div>
                        </div>
                    </div>
                    {/* select time ends */}
                    <div className="action-buttpm text-right">
                        <Button type="primary">Proceed</Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Delivery)