import React from 'react';
import { withRouter } from 'react-router';
import {
    BrowserRouter as Router,
    Link
} from 'react-router-dom';
import './RentYourFurnitures.scss';
import { Row, Col } from 'antd';

class RentYourFurnitures extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* rent your furniture starts */}
                <section className="rent-furniture">
                    <div className="container mx-auto">
                        <Row gutter={[22, 20]} align="center">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                <div className="section-title">
                                    <h2>Rent your furniture with us and earn money</h2>
                                    <p>Let us help scale your business and make your life wonderful!! We handle the dirty work! We can store & manage your furniture in our warehouse, schedule pickup & delivery using our movers so all you need to do is select from your own items using our website! Plus, you can select items for a COOP to earn money while you sleep.</p>
                                </div>
                                <Row align="center">
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/shopping_cart.svg")} alt="" />
                                            <p>Rent your furniture</p>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/airport_shuttle_black.svg")} alt="" />
                                            <p>We will rent further to Our customers</p>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/paid_black.svg")} alt="" />
                                            <p>You Earn</p>
                                        </div>
                                    </Col>
                                </Row>
                                {/* icons end */}

                                <div className="call-to-action">
                                    <Row align="center">
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt  </p>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <Link className="btn btn-secondary">Know More</Link>
                                        </Col>
                                    </Row>
                                </div>
                                {/* cta ends */}
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <div className="designer-service">
                                    <h3>Designer Service</h3>
                                    <p>We’ve got a better way, just for Charlotte Home Staging Professionals</p>
                                    <h4>We take the stress out of…</h4>
                                    <ul>
                                        <li>Shipment Receiving</li>
                                        <li>Shipment Inspection</li>
                                        <li>Professional Assembly</li>
                                        <li>Secure, Clean Storage</li>
                                        <li>White Glove Delivery</li>
                                        <li>Online pictures & documents with project updates</li>
                                    </ul>
                                    <Link className="btn btn-primary">Learn More</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                {/* rent your furniture ends */}
            </React.Fragment>
        )
    }
}
export default withRouter(RentYourFurnitures)