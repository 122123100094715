import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';

class Bundles extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners bundles">
                    <div className="container mx-auto">
                        <h1>Bundles</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">
                        <div className="choose-room-type">
                            <h2>Choose by Room Type</h2>
                            <Row gutter={[12, 20]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link to="/bundles-listing">
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/room-type-1.webp')} alt="Living Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Living Room</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/room-type-2.webp')} alt="Bed Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Bed Room</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/room-type-3.webp')} alt="Dining Room" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Dining Room</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/room-type-4.webp')} alt="Office" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Office</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/room-type-5.webp')} alt="Outdoor" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Outdoor</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                            </Row>
                        </div>
                        {/* choose by room type ends */}
                        <div className="choose-room-type">
                            <h2>Choose by Apartment Type</h2>
                            <Row gutter={[12, 20]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/apartment-type-1.webp')} alt="Studio" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Studio</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/apartment-type-2.webp')} alt="One Bed Room Apartment" />
                                            </div>
                                            <div className="details">
                                                <div className="title">One Bed Room Apartment</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Link>
                                        <div className="room-type">
                                            <div className="image">
                                                <img src={require('../../assets/images/bundles/apartment-type-3.webp')} alt="Two Bed Room Apartment" />
                                            </div>
                                            <div className="details">
                                                <div className="title">Two Bed Room Apartment</div>
                                                <div className="total-bundles">12 Bundles</div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                {/* bundle list ends */}
                            </Row>
                        </div>
                        {/* choose by Apartment type ends */}
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
export default withRouter(Bundles)